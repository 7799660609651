@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,500&display=swap');

/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
    transition: transform 300ms ease-in-out;
    transition-delay: 100ms;
    transform: scale(0);
    /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open{
    transform: scale(1);
    /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close{
    transform: scale(0);
    /* transform: translateY(-150%); */
}
#new-rate{
    color: black;
    font-size: 24px;
}

body{
    font-family: 'Montserrat', sans-serif;;
}