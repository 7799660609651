@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,500&display=swap);
/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition-delay: 100ms;
    -webkit-transform: scale(0);
            transform: scale(0);
    /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open{
    -webkit-transform: scale(1);
            transform: scale(1);
    /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close{
    -webkit-transform: scale(0);
            transform: scale(0);
    /* transform: translateY(-150%); */
}
#new-rate{
    color: black;
    font-size: 24px;
}

body{
    font-family: 'Montserrat', sans-serif;;
}
.makeStyles-spinner-1 {
  margin-top: 0 !important;
}
/* .MuiPaper-root.MuiCard-root.MuiPaper-elevation1.MuiPaper-rounded {
    width: 50%;
} */
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
.iTSDrX {
  width: 35%;
  height: auto !important;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.invoice-box {
  max-width: 100%;
  margin: auto;
  padding: 30px;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 24px;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  color: #555;
}

.invoice-box-2 {
  max-width: 100%;
  margin: auto;
  padding: 30px;
  /* border: 1px solid #eee; */

  font-size: 16px;
  line-height: 24px;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  color: #555;
}
.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 45px;
  line-height: 45px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information table td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/* RTL */
.rtl {
  direction: rtl;
  font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial,
    sans-serif;
}

.rtl table {
  text-align: right;
}

.rtl table tr td:nth-child(2) {
  text-align: left;
}

.error-page {
  display: -webkit-flex;
  display: flex;
  /* background-image: url("../../assets/500.svg"); */

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* align-items: center;
justify-content: center;
background-size: 50%;
background-repeat: no-repeat;
background-position: center; */
  background: rgb(248, 248, 248);
  -webkit-flex-direction: column;
          flex-direction: column;
}
.error-image {
  height: 50%;
  background-image: url(/static/media/500.6420da51.svg);
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-size: 30%;
  background-repeat: no-repeat;
  background-position: center;
}

.error-instruction {
  text-align: center;
  height: 50%;
  color: slategray;
}

.error-header {
  font-size: 40px;
}

